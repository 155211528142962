import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';

import { MenuButton } from '../shared/ReactToolbox';
import { useLogin, useAuth } from '../shared/Login';
import { use } from '../../redux/factory';
import Sidebar, { NavLinks } from './Sidebar';

const Dashboard = () => {
  const { getUsersList } = use.users();
  const { getDealersList } = use.dealers();
  const { getDossiersList } = use.dossiers();
  const { getVehiclesList } = use.vehicles();
  const { getHexonDealersList } = use.hexonDealers();
  const { getNotarieelBetalenAccountsList } = use.notarieelBetalenAccounts();
  useEffect(() => {
    getUsersList();
    getDossiersList();
    getDealersList();
    getVehiclesList();
    getHexonDealersList();
    getNotarieelBetalenAccountsList();
  }, []);
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useAuth();
  const { logout } = useLogin();

  return (
    <>
      <Navbar bg='light'>
        <Link to='/'>
          <Navbar.Brand>Dashboard</Navbar.Brand>
        </Link>
        <MenuButton
          className='p-0 d-md-none ms-3'
          size='25'
          onClick={() => setShowMenu(!showMenu)}
        />
        <Nav className='mr-auto'>
          <Navbar.Text id='dashboard-title'>
            <div></div>
          </Navbar.Text>
        </Nav>
        <Navbar.Collapse className='justify-content-end'>
          <Navbar.Text>
            <b>{user &&(user.name || user.username)}</b>
          </Navbar.Text>
          <Nav.Link onClick={() => logout()}>Logout</Nav.Link>
        </Navbar.Collapse>
      </Navbar>
      {showMenu &&
        <div style={{ width: '100%'}} className='bg-light'>
          <div className='p-0 d-md-none ms-3'>
            <div className='divider' />
            <Nav className='d-block'>
              <NavLinks />
            </Nav>
          </div>
        </div>
      }
      <Sidebar />
    </>
  );
};

export default Dashboard;

// Or use context providers https://stackoverflow.com/questions/70655186/is-it-possible-to-use-multiple-outlets-in-a-component-in-react-router-v6
export const DashboardTitle = ({ children }) => document.getElementById('dashboard-title') && createPortal(children, document.getElementById('dashboard-title'))
