import moment from 'moment';
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';

import { use } from '../../redux/factory';
import {
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  SmallSpinner,
  CreateButton,
  DataTable,
  useSetState,
  DeleteConfirmButton,
  SyncButton,
  QuestionnaireButton,
  CheckIndicator,
} from '../shared/ReactToolbox';
import RdwSettingsModal from './RdwSettingsModal';
import HexonDealerFormField from './HexonDealerFormField';
import HexonDealerModal from './HexonDealerModal';
import NotarieelBetalenAccountFormField from './NotarieelBetalenAccountFormField';

const DealersList = () => {
  const {
    dealersList,
    createDealer,
    updateDealer,
    deleteDealer,
    getDealersList,
  } = use.dealers();
  const { hexonDealersList, getHexonDealersList } = use.hexonDealers();
  const {
    notarieelBetalenAccountsList,
    getNotarieelBetalenAccountsList,
    getRdwSettings,
    clearRdwSettings,
    rdwSettings,
  } = use.notarieelBetalenAccounts();
  useEffect(() => {
    getDealersList();
    getHexonDealersList();
    getNotarieelBetalenAccountsList();
  }, []);
  const [state, setState] = useSetState({
    createModalActive: false,
    dealerInEditModal: null,
    hexonDealerIdInModal: null,
  })
  const { createModalActive, dealerInEditModal, hexonDealerIdInModal } = state;
  if (!dealersList || !hexonDealersList || !notarieelBetalenAccountsList) return <SmallSpinner className='vertical-space' />;
  
  const dealerFormFields = {
    notarieelbetalen_account_id: {
      label: 'Koppeling met Notarieel Betalen',
      component: NotarieelBetalenAccountFormField,
    },
    hexon_dealer_id: {
      label: 'Koppeling met Hexon',
      component: HexonDealerFormField,
    },
    name: {
      label: 'Dealer',
      required: true,
    },
    reporting_code: {
      label: 'Meldcode',
      formProps: { maxLength: 10 },
    },
    api_key: {
      label: 
        dealerInEditModal && dealerInEditModal.has_api_key
          ? 'Api sleutel is opgeslagen, laat leeg om deze ongewijzigd te laten. '
          : 'Api sleutel, log in op de subaccount om deze aan te maken.',
    },
    ascription_master_key: {
      label: 
        dealerInEditModal && dealerInEditModal.has_ascription_master_key
          ? 'Tenaamstelling hoofdsleutel is opgeslagen, laat leeg om deze ongewijzigd te laten. '
          : 'Tenaamstelling hoofdsleutel, log in op de subaccount en upload het RDW-certificaat om deze aan te maken.',
    },
  };

  const hexonDealerColumns = [
    {
      name: 'Klantnummer',
      selector: ({ act_klantnummer, act_klantnummer_confirmed }) =>
        `${act_klantnummer}${act_klantnummer_confirmed ? '' : ' (Nog niet bevestigd)'}`
    }, {
      name: 'Naam',
      selector: ({ name, outstanding_request }) =>
        <>
          {outstanding_request && 
            <QuestionnaireButton
              className='float-end'
              variant='success'
              onClick={() => {}}
            />
          }
          {name}
        </>,
    }, {
      name: 'Postcode',
      selector: 'postal_code',
    }, {
      name: 'Plaats',
      selector: 'city',
    }, {
      name: 'Telefoon',
      selector: 'telephone',
    }, {
      name: 'Site/reseller',
      // orderBy: 'permission_site',
      selector: ({ permission_site, permission_reseller }) =>
        <span>
          <CheckIndicator className='float-end icon-button' checked={permission_reseller} />
          <CheckIndicator className='float-end' checked={permission_site} />
        </span>
    }, {
      name: 'Reseller',
      selector: 'reseller_code',
    },
  ];

  return <>
    <Container className="container-list">
      <h4>Gekoppelde dealers</h4>
      <DataTable
        showHeader={false}
        columns={[
          {
            name: 'Dealer',
            selector: 'name',
          },
          {
            name: 'Certificaat geldig tot',
            selector: ({ rdw_certificate_valid_until }) => {
              if (!rdw_certificate_valid_until) return <i>N.v.t.</i>;

              const valid_until = moment(rdw_certificate_valid_until);
              return (
                <strong className={
                    valid_until < moment() 
                    ? 'text-danger'
                    : valid_until < moment().add(1, 'month')
                    ? 'text-warning'
                    : 'text-success'
                  }
                >
                  {valid_until.format('DD-MM-YYYY HH:mm')}
                </strong>
              );
            },
          },
          {
            name: 'Rdw status',
            selector: dealer => dealer.has_api_key
              ? <>
                  <SyncButton
                    className='float-end'
                    title='Rdw status ophalen uit Notarieel Betalen'
                    disabled={!dealer.has_api_key}
                    loading={getRdwSettings.isLoading && getRdwSettings.data.id === dealer.id}
                    onClick={() => getRdwSettings(dealer)}
                  />
                  <CheckIndicator
                    className='float-end'
                    style={{ marginRight: '4px' }}
                    checked={dealer.can_use_rdw_ascription}
                  />
                </>
              : <i  className='float-end'>Geen api sleutel</i>}
          ,
          {
            name: 'Acties',
            selector:
              dealer => <>
                <DeleteConfirmButton
                  className='float-end'
                  onDelete={() => deleteDealer(dealer)}
                />
              </>,
          },
        ]}
        onClickRow={dealerInEditModal => setState({ dealerInEditModal })}
        data={Object.values(dealersList)}
        orderByDefault='id'
      />
      <CreateButton onClick={() => setState({ createModalActive: true })} />

      <h4 className='vertical-space'>Dealers in Hexon</h4>
      <DataTable
        columns={hexonDealerColumns}
        
        filterColumn={
          ({ act_klantnummer, name, postal_code, city, telephone, reseller_code }) =>
            `${act_klantnummer} ${name} ${postal_code} ${city} ${telephone} ${reseller_code}`
        }
        data={Object.values(hexonDealersList).sort((d1, d2) => d1.outstanding_request && !d2.outstanding_request ? -1 : 1)}
        onClickRow={({ act_klantnummer }) => setState({ hexonDealerIdInModal: act_klantnummer })}
      />

      {dealerInEditModal &&
        <EditModal
          modalTitle='Dealer aanpassen'
          onHide={() => setState({ dealerInEditModal: null })}
          loading={updateDealer.isLoading}
          initialState={dealerInEditModal}
          formFields={dealerFormFields}
          onSave={dealer => updateDealer(
            dealer,
            { callback: () => setState({ dealerInEditModal: null }) }
          )}
        />
      }
      {createModalActive &&
        <CreateModal
          onHide={() => setState({ createModalActive: false })}
          modalTitle='Dealer aanmaken'
          loading={createDealer.isLoading}
          formFields={dealerFormFields}
          onSave={dealer => createDealer(
            dealer,
            { callback: () => setState({ createModalActive: false }) }
          )}
        />
      }
      {rdwSettings &&
        <RdwSettingsModal
          onHide={clearRdwSettings}
          rdwSettings={rdwSettings}
        />
      }
      {hexonDealerIdInModal &&
        <HexonDealerModal
          id={hexonDealerIdInModal}
          onHide={() => setState({ hexonDealerIdInModal: null })}
        />
      }
    </Container>
  </>;
};

export default DealersList
