import { use } from '../../redux/factory';
import { FormBadgesSelection } from '../shared/ReactToolbox';

const DeliverersFormField = props => {
  const { usersList } = use.users();
  // Either select one user or multiple users depending of value prop is an array or not.
  return (
    <FormBadgesSelection
      list={Object.values(usersList).filter(({ is_staff }) => !is_staff)}
      style={{ marginRight: '4px' }}
      multiple
      {...props}
    />
  );
}

export default DeliverersFormField;