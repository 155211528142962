import { useEffect } from 'react';
import { Container } from 'react-bootstrap';

import { use } from '../../redux/factory';
import {
  CreateEditModal as EditModal,
  SmallSpinner,
  CreateButton,
  DataTable,
  useSetState,
} from '../shared/ReactToolbox';
import dossierColumns from './dossierColumns';
import dossierFormFields from './dossierFormFields';

const DossiersList = () => {
  const { dossiersList, getDossiersList, updateDossier } = use.dossiers();
  const { dealersList, getDealersList } = use.dealers();
  const { getHexonDealersList, hexonDealersList } = use.hexonDealers();
  useEffect(() => {
    getDossiersList();
    getHexonDealersList();
    getDealersList();
  }, []);
  const [state, setState] = useSetState({
    createModalActive: false,
    dossierInEditModal: null,
  })
  const { dossierInEditModal } = state;
  if (!dossiersList || !hexonDealersList || !dealersList) return <SmallSpinner className='vertical-space' />;

  return <>
    <Container className='container-list'>
      <h4>Proefritten</h4>
      <DataTable
        showHeader={true}
        columns={dossierColumns}
        data={Object.values(dossiersList)}
        orderByDefault='id'
        onClickRow={dossierInEditModal =>
          setState({ dossierInEditModal })
        }
      />

      {dossierInEditModal &&
        <EditModal
          onHide={() => setState({ dossierInEditModal: null })}
          modalTitle='Proefrit aanpassen'
          loading={updateDossier.isLoading}
          initialState={dossierInEditModal}
          formFields={dossierFormFields}
          onSave={dossier => updateDossier(
            dossier,
            { callback: () => setState({ dossierInEditModal: null }) }
          )}
        />
      }
    </Container>
  </>;
};

export default DossiersList
