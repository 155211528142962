import './App.css';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Root from './Root';
import { LocalizationProvider } from './components/shared/ReactToolbox';
import { Login } from './components/shared/Login';
import AuthenticatedRoute from './components/login/AuthenticatedRoute';
import Dashboard from './components/dashboard/Dashboard';
import VehiclesList from './components/dossiers/VehiclesList';
import DealersList from './components/dealers/DealersList';
import DossiersList from './components/dossiers/DossiersList';
import UsersList from './components/users/UsersList';

export const routes = [
  {
    path: '',
    title: 'Beschikbare auto\'s',
    children: [
      {
        index: true,
      },
    ],
  },
  {
    path: 'dossiers',
    title: 'Proefritten',
    children: [
      {
        index: true,
      },
    ],
  },
  {
    path: 'users',
    title: 'Gebruikers',
    children: [
      {
        index: true,
      },
    ],
  },
  {
    path: 'dealers',
    title: 'Dealers',
    children: [
      {
        index: true,
      },
    ],
  },
];

const App = () => {
  return (
    // <DndProvider backend={HTML5Backend}>
      <Root>
        <LocalizationProvider lang='nl'>
          <ToastContainer hideProgressBar={true} newestOnTop={true} />
          
          <Routes>
            <Route
              path="/login"
              element={
              <Login />}
            />
            <Route
              exact
              path="/"
              element={<AuthenticatedRoute component={Dashboard} redirectNonStaffUserTo={process.env.REACT_APP_WEBAPP_URL} />}
            >
              <Route
                path=""
                index={true}
                element={<VehiclesList />}
              />
              <Route path="dossiers" element={<DossiersList />} />
              <Route path="users" element={<UsersList />} />
              <Route path="dealers" element={<DealersList />} />
            </Route>
          </Routes>
        </LocalizationProvider>
      </Root>
    // </DndProvider>
  );
}

export default App;
