import { FormSelect } from '../shared/ReactToolbox';
import { use } from '../../redux/factory';

const HexonDealerFormField = ({
  value,
  initialValue,
  ...restProps
}) => {
  const { hexonDealersList } = use.hexonDealers();
  const { dealersList } = use.dealers();
  const idsDisabled = (
    Object.values(dealersList)
    .map(({ hexon_dealer_id }) => hexon_dealer_id)
    .filter(id => initialValue !== id)
  )

  return (
    <FormSelect
      value={value}
      {...restProps}
      id='act_klantnummer'
      list={
        Object.values(hexonDealersList)
          .filter(({ permission_site, permission_reseller}) => permission_site && permission_reseller)
          .sort((d1, d2) => d1.name > d2.name ? 1 : -1)
      }
      disabled={({ value }) => idsDisabled.includes(value)}
      integer
      formatTitle={({ name }) => name}
    />
  );
}

export default HexonDealerFormField;