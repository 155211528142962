import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { use } from '../../redux/factory';
import {
  CreateEditModal as CreateModal,
  SmallSpinner,
  DataTable,
  useSetState,
} from '../shared/ReactToolbox';
import dossierFormFields from './dossierFormFields';
import { formatPrice, formatLicensePlate } from '../../utils/Utils';
import HexonAlert from './HexonAlert';
import DealerAlerts from '../dealers/DealerAlerts';

const VehiclesList = () => {
  const { createDossier, dossiersList } = use.dossiers();
  const { dealersList, getDealersList } = use.dealers();
  const { getVehiclesList, vehiclesList } = use.vehicles();
  const { getHexonDealersList, hexonDealersList } = use.hexonDealers();
  useEffect(() => {
    getVehiclesList();
    getHexonDealersList();
    getDealersList();
  }, []);
  const [{ dossierInCreateModal }, setState] = useSetState({
    dossierInCreateModal: null,
  })
  const navigate = useNavigate();
  if (!vehiclesList || !hexonDealersList || !dealersList || !dossiersList) return <SmallSpinner className='vertical-space' />;
  
  const outstandingRequestCount = Object.values(hexonDealersList).filter(({ outstanding_request }) => outstanding_request).length;
  // Ids of dealers to allow
  const hexonDealerIds = (
    Object.values(dealersList)
      .filter(({ can_use_rdw_ascription }) => !!can_use_rdw_ascription)
      .map(({ hexon_dealer_id }) => hexon_dealer_id)
  );
  // Ids of vehicles to filter out as there is already a dossier
  const hexonVehicleIds = Object.values(dossiersList).map(({ hexon_vehicle_id }) => hexon_vehicle_id);
  const vehiclesFiltered = (
    Object.values(vehiclesList)
      .filter(
        ({ customerNumber, vehicleNumberHexon }) =>
          hexonDealerIds.includes(customerNumber)
          && !hexonVehicleIds.includes(vehicleNumberHexon))
  );
  const vehicleColumns = [
    {
      orderBy: 'brand',
      selector: 'brand',
      name: 'Merk',
    },
    {
      orderBy: 'model',
      selector: 'model',
      name: 'Model',
    },
    {
      orderBy: 'type',
      selector: 'type',
      name: 'Type',
    },
    {
      orderBy: 'licensePlate',
      selector: ({ licensePlate }) => formatLicensePlate(licensePlate),
      name: 'Kenteken',
    },
    {
      orderBy: 'price',
      selector: ({ price }) => formatPrice(price),
      name: 'Prijs',
    },
    {
      selector: ({ customerNumber }) => {
        const dealer = Object.values(dealersList).find(({ hexon_dealer_id }) => hexon_dealer_id === customerNumber);
        if (!dealer) return <i>Niet gevonden</i>;

        return dealer.name;
      },
      name: 'Dealer',
    },
  ];
  return <>
    <Container className="container-list">
      <DealerAlerts />
      <HexonAlert />
      <h4>Beschikbare auto's ({vehiclesFiltered.length})</h4>
      <DataTable
        columns={vehicleColumns}
        filterColumn={
          ({ brand, model, type, licensePlate, price, customerNumber }) =>
            `${
              brand
            } ${
              model
            } ${
              type
            } ${
              formatLicensePlate(licensePlate)
            } ${
              price.toFixed(2)
            } ${
              hexonDealersList[customerNumber] ? hexonDealersList[customerNumber].name : ''
            }`
        }
        data={vehiclesFiltered}
        orderByDefault='id'
        onClickRow={
          ({ brand, model, type, licensePlate, price, customerNumber, vehicleNumberHexon }) => {
            const dealer = (
              Object.values(dealersList)
                .find(({ hexon_dealer_id }) => hexon_dealer_id === customerNumber)
            );

            setState({
              dossierInCreateModal: {
                brand,
                model,
                type,
                license_plate: licensePlate,
                price,
                hexon_vehicle_id: vehicleNumberHexon,
                dealer: dealer && dealer.id,
              },
            })
          }
        }
      />

      {dossierInCreateModal &&
        <CreateModal
          width='50'
          onHide={() => setState({ dossierInCreateModal: null })}
          initialState={dossierInCreateModal}
          modalTitle={
            'Proefrit aanmaken'
            // `Proefrit aanmaken voor ${dossierInCreateModal.brand} ${dossierInCreateModal.model} (${dossierInCreateModal.license_plate})`
          }
          loading={createDossier.isLoading}
          formFields={dossierFormFields}
          onSave={dossier => createDossier(
            dossier,
            { callback: () => navigate('/dossiers') }
          )}
        />
      }
    </Container>
  </>;
};

export default VehiclesList
