import { Container, Modal, Button, Row, Col } from 'react-bootstrap';
import { use } from '../../redux/factory';
import { ConfirmButton, CheckIndicator, CheckButton, UnCheckButton } from '../shared/ReactToolbox';

const HexonDealerModal = ({ id, onHide }) => {
  const { hexonDealer, acceptHexonDealer, denyHexonDealer } = use.hexonDealers(id);

  return hexonDealer && (
    <Modal
      show={true}
      onHide={onHide}
      onClick={e => e.stopPropagation()}
      centered
      dialogClassName='mw-100 w-50'
    >
      <Modal.Header closeButton>
        <Modal.Title>{hexonDealer.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Container fluid>
          <Row className='list-row'>
            <Col>Permissie site</Col>
            <Col>
              {<CheckIndicator className='icon-button' checked={hexonDealer.permission_site} />}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Reseller permissie</Col>
            <Col>
              {<CheckIndicator className='icon-button' checked={hexonDealer.permission_reseller} />}
            </Col>
          </Row>
          {hexonDealer.outstanding_request &&
            <Row className='list-row'>
              <Col>Openstaande aanvraag</Col>
              <Col>
                {hexonDealer.outstanding_request &&
                  <ConfirmButton
                    buttonComponent={CheckButton}
                    variant='success'
                    loading={acceptHexonDealer.isLoading}
                    onConfirm={() => acceptHexonDealer(hexonDealer)}
                    modalTitle='Dealer accepteren'
                  >
                    Accepteren
                  </ConfirmButton>
                }
                <ConfirmButton
                  buttonComponent={UnCheckButton}
                  variant='danger'
                  loading={denyHexonDealer.isLoading}
                  onConfirm={() => denyHexonDealer(hexonDealer)}
                  modalTitle='Dealer weigeren'
                >
                  Weigeren
                </ConfirmButton>
              </Col>
            </Row>
          }
          {hexonDealer.permission_site && hexonDealer.permission_reseller &&
            <Row className='list-row'>
              <Col>Acties</Col>
              <Col>
                <ConfirmButton
                  buttonComponent={UnCheckButton}
                  variant='danger'
                  loading={denyHexonDealer.isLoading}
                  onConfirm={() => denyHexonDealer(hexonDealer)}
                  modalTitle='Dealer afwijzen'
                >
                  Afwijzen
                </ConfirmButton>
              </Col>
            </Row>
          }<Row className='list-row'>
            <Col>Klantnummer</Col>
            <Col>
              {`${hexonDealer.act_klantnummer}${hexonDealer.act_klantnummer_confirmed ? '' : ' (Nog niet bevestigd)'}`}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Hexon <i>Doorlink Vooraad</i> id</Col>
            <Col>
              {hexonDealer.id}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Adres</Col>
            <Col>
              {hexonDealer.street}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Postcode</Col>
            <Col>
              {hexonDealer.postal_code}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Land</Col>
            <Col>
              {hexonDealer.country === 'nl' ? 'Nederland' : hexonDealer.country}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Telefoonnummer</Col>
            <Col>
              {hexonDealer.telephone}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Faxnummer</Col>
            <Col>
              {hexonDealer.fax}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Email</Col>
            <Col>
              {hexonDealer.email && <a href={`mailto:${hexonDealer.email}`}>{hexonDealer.email}</a>}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Website</Col>
            <Col>
              {hexonDealer.website && <a href={hexonDealer.website}>{hexonDealer.website}</a>}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Reseller</Col>
            <Col>
              {hexonDealer.reseller_code}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Software</Col>
            <Col>
              {hexonDealer.inventory_management_system}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Aantal voertuigen geselecteerd</Col>
            <Col>
              {hexonDealer.num_vehicles_selected}
            </Col>
          </Row>
          <Row className='list-row'>
            <Col>Aantal voertuigen geadverteerd</Col>
            <Col>
              {hexonDealer.num_ads_advertised}
            </Col>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant='primary'
          onClick={onHide}
        >
          Sluiten
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HexonDealerModal;