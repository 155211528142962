import moment from 'moment';
import { Alert } from 'react-bootstrap';
import { use } from '../../redux/factory';
            
const DealerAlerts = () => {
  const { dealersList } = use.dealers();

  if (!dealersList) return null;

  return Object.values(dealersList).map((dealer, index) => {
    if (!dealer.rdw_certificate_valid_until) return null;
    const valid_until = moment(dealer.rdw_certificate_valid_until);
    
    if (valid_until >= moment().add(1, 'month')) return null;

    const days = valid_until.diff(moment(), 'days')
    return (
      <Alert
        className='vertical-space-below'
        variant={days <= 0 ? 'danger' : 'warning'}
      >
        {days > 0
          ? `Het RDW certificaat van ${dealer.name} is nog ${days} ${days === 1 ? 'dag' : 'dagen'}
            geldig en verloopt op ${valid_until.format('DD-MM-YYYY HH:mm')}.`
          : `Het RDW certificaat van ${dealer.name} is verlopen.
            De automatische tenaamstelling is niet meer mogelijk.`
        }
      </Alert>
    );
  })
}

export default DealerAlerts;