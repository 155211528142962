import axios from '../utils/Axios';
import { toastOnError } from '../utils/Utils';
import reduxCrudFactory from '../redux/ReduxCrudFactory';

const factory = reduxCrudFactory({
  axios,
  onError: toastOnError,
  parseIdToInt: true,
  config: {
    users: {
      route: '/api/admin/users/',
    },
    dossiers: {
      route: '/api/admin/dossiers/',
      includeActions: {

      }
    },
    dealers: {
      route: '/api/admin/dealers/',
    },
    vehicles: {
      id: 'vehicleNumberHexon',
      route: '/api/admin/proefritaanhuis/vehicles/',
      actions: {
        getList: true,
      },
    },
    hexonDealers: {
      id: 'act_klantnummer',
      route: '/api/admin/hexon/dealers/',
      actions: {
        getList: true,
      },
      includeActions: {
        acceptHexonDealer: {
          method: 'post',
          route: () => `/api/admin/hexon/dealers/accept/`,
          onResponse: (hexonDealer, { setHexonDealer }) => setHexonDealer(hexonDealer),
        },
        denyHexonDealer: {
          method: 'post',
          route: () => `/api/admin/hexon/dealers/deny/`,
          onResponse: (hexonDealer, { setHexonDealer }) => setHexonDealer(hexonDealer),
        },
      }
    },
    notarieelBetalenAccounts: {
      route: '/api/admin/notarieel-betalen/accounts/',
      actions: {
        getList: {
          prepareResponse: ({ Accounts, count }, { setCount }) => {
            setCount(count - 1);
            return Accounts.slice(1);
          },
        },
      },
      state: {
        count: null,
        rdwSettings: null,
      },
      includeActions: {
        exportUser: {
          route: user => `/api/admin/notarieel-betalen/export-user/${user.id}/`,
          onResponse: (user, { setUser }) => setUser(user),
        },
        getRdwSettings: {
          route: dealer => `/api/admin/notarieel-betalen/rdw-settings/${dealer.id}/`,
          onResponse: 
            ({ rdw_settings, dealer }, { setRdwSettings, setDealer }) => {
              setDealer(dealer);
              setRdwSettings({
                ...rdw_settings,
                dealer,
              });
            },
        },
        exportDossier: {
          method: 'post',
          route: dossier => `/api/admin/notarieel-betalen/export-dossier/${dossier.id}/`,
          onResponse: (dossier, { setDossier }) => setDossier(dossier),
        },
        startDossier: {
          method: 'post',
          route: dossier => `/api/admin/notarieel-betalen/start-dossier/${dossier.id}/`,
          onResponse: (dossier, { setDossier }) => setDossier(dossier),
        },
      },
    },
  },
});

export default factory;

export const actions = factory.actions;
export const mapToProps = factory.mapToProps;
export const use = factory.hooks;
