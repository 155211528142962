import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { use } from '../../redux/factory';

const HexonAlert = () => {
  const { hexonDealersList } = use.hexonDealers();
  const outstandingRequestCount = Object.values(hexonDealersList).filter(({ outstanding_request }) => outstanding_request).length;
  
  return (
    outstandingRequestCount
      ? <>
          <Alert className='vertical-space-below' variant='info'>
            <Link to='/dealers'>
              Aantal openstaande aanvragen in Hexon: {outstandingRequestCount}
            </Link>
          </Alert>
        </>
      : null
    
  );
}

export default HexonAlert;