import moment from 'moment';
import { DeleteConfirmButton, UploadButton, PlayButton } from '../shared/ReactToolbox';
import { use } from '../../redux/factory';

import Dealer from '../dealers/Dealer';
import User from '../users/User';
import { formatLicensePlate } from '../../utils/Utils';
import { Badge } from 'react-bootstrap';

const Actions = ({ dossier }) => {
  const { deleteDossier } = use.dossiers();
  const { exportDossier, startDossier } = use.notarieelBetalenAccounts();
  
  return <>
    <DeleteConfirmButton
      className='float-end'
      onDelete={() => deleteDossier(dossier)}
    />
    {!dossier.notarieelbetalen_dossier_id &&
      <UploadButton
        onClick={() => exportDossier(dossier)}
        loading={exportDossier.isLoading}
      >
        Exporteren
      </UploadButton>
    }
    {dossier.notarieelbetalen_dossier_id && !dossier.notarieelbetalen_started_at &&
      <PlayButton
        onClick={() => startDossier(dossier)}
        loading={startDossier.isLoading}
      >
        Starten
      </PlayButton>
    }
  </>;
}
const dossierColumns = [
  {
    name: 'Naam',
    selector: 
      ({ gender, first_name, last_name }) =>
        `${
          gender === 'm'
          ? 'Dhr. '
          : gender === 'f'
          ? 'Mevr. '
          : ''
        }${
          first_name
        } ${
          last_name
        }`,
  },
  {
    name: 'Adres',
    selector: ({ street, city, zipcode }) =>
      <>{street}<br />{zipcode} {city}</>,
  },
  {
    name: 'Contact',
    selector: ({ email, phone_number }) =>
      <>{email}<br />{phone_number}</>,
  },
  {
    name: 'Auto',
    selector: ({ brand, model, type, license_plate, price }) =>
    
    <>{brand} {model}<br />{formatLicensePlate(license_plate)}</>,
  },
  {
    name: 'Dealer',
    selector: ({ dealer }) => <Dealer id={dealer} />,
  },
  {
    name: 'Bezorgers',
    selector: ({ deliverers }) => deliverers && deliverers.map((user, index) => <User id={user} key={index} />)
  },
  {
    name: 'Datum',
    selector: ({ test_drive_date }) => test_drive_date &&
      moment(test_drive_date).format('DD-MM-YYYY HH:mm')
  },
  {
    name: 'Status',
    selector: dossier =>
      !dossier.notarieelbetalen_dossier_id 
      ? <Badge bg='warning'>Aangemaakt</Badge>
      : !dossier.notarieelbetalen_started_at
      ? <Badge bg='primary'>Geëxporteerd</Badge>
      : <Badge bg='success'>Gestart</Badge>
  },
  {
    name: 'Acties',
    selector: dossier => <Actions dossier={dossier} />,
  },
];

export default dossierColumns;