import { Container, Modal, Button, Row, Col } from 'react-bootstrap';
import { CheckIndicator } from '../shared/ReactToolbox';

const RdwSettingsModal = ({ rdwSettings, onHide }) => (
  <Modal
    show={true}
    onHide={onHide}
    onClick={e => e.stopPropagation()}
    centered
    dialogClassName='mw-100 w-50'
  >
    <Modal.Header closeButton>
      <Modal.Title>Rdw status van {rdwSettings.dealer.name} in Notarieel Betalen</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Container fluid>
        <Row className='list-row'>
          <Col>Rdw certificaat is toegevoegd</Col>
          <Col>
            <CheckIndicator checked={rdwSettings.has_cert_submitted} />
          </Col>
        </Row>
        <Row className='list-row'>
          <Col>Gecheckt volgens de regels van RDW</Col>
          <Col>
            <CheckIndicator checked={rdwSettings.checked_conform_rdw_rules !== '0'} />
          </Col>
        </Row>
        <Row className='list-row'>
          <Col>Overdracht wordt automatisch geaccepteerd</Col>
          <Col>
            <CheckIndicator checked={rdwSettings.auto_approve_ascription !== '0'} />
          </Col>
        </Row>
        <Row className='list-row'>
          <Col>Authorisatie medewerker</Col>
          <Col>
            {rdwSettings.authorization_employee_name || <CheckIndicator checked={false} />}
          </Col>
        </Row>
        <Row className='list-row'>
          <Col>Authorisatie telefoonnummer</Col>
          <Col>
            {rdwSettings.ascription_phone || <CheckIndicator checked={false} />}
          </Col>
        </Row>
        <Row className='list-row'>
          <Col>Authorisatie handtekening is gezet</Col>
          <Col>
            <CheckIndicator checked={rdwSettings.has_authorization_employee_signature} />
          </Col>
        </Row>
        <Row className='list-row'>
          <Col>Dossiers kunnen worden aangemaakt</Col>
          <Col>
            <CheckIndicator checked={rdwSettings.can_create_ascription_dossiers} />
          </Col>
        </Row>
      </Container>
    </Modal.Body>

    <Modal.Footer>
      <Button
        variant='primary'
        onClick={onHide}
      >
        Sluiten
      </Button>
    </Modal.Footer>
  </Modal>
);

export default RdwSettingsModal;