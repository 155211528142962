import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from './redux/reducer';

export const history = createBrowserHistory();

const store = createStore(
  rootReducer(history),
  {},
  compose(
    applyMiddleware(thunk, routerMiddleware(history))
  )
);
export default store;