import { Badge } from 'react-bootstrap';
import { use } from '../../redux/factory';

const User = ({ cursor, id, bg = 'primary', ...props }) => {
  const { user } = use.users(id);
  return (
    <Badge
      bg={bg}
      className='user'
      style={{ marginRight: '4px' }}
      {...props}
    >
      {user ?  user.name || user.username : <i>niet gevonden</i>}
    </Badge>
  );
}

export default User;
