import React from 'react';
import { FormSelect, FormTextArea, FormDateTime, FormInput } from '../shared/ReactToolbox';
import { formatLicensePlate } from '../../utils/Utils';

import { use } from '../../redux/factory';
import DeliverersFormField from '../users/DeliverersFormField';

const FormPrice = ({ state: { price }, ...restProps }) => (
  <FormInput
    disabled
    value={`€ ${price.toFixed(2)}`}
  />
);

const FormLicensePlate = ({ state: { license_plate }, ...restProps }) => (
  <FormInput
    disabled
    value={formatLicensePlate(license_plate)}
  />
);

const DealerFormField = ({ value }) => {
  const { dealer } = use.dealers(value);
  return (
    dealer
      ? <FormInput value={dealer.name} disabled />
      : <div className='form-control is-invalid'>
          <i>Niet gevonden</i>
        </div>
  );
}

const dossierFormFields = {
  brand: {
    label: 'Merk',
    formProps: { disabled: true },
  },
  model: {
    label: 'Model',
    formProps: { disabled: true },
  },
  type: {
    label: 'Type',
    formProps: { disabled: true },
  },
  license_plate: {
    label: 'Kenteken',
    component: FormLicensePlate,
  },
  price: {
    label: 'Prijs',
    component: FormPrice,
  },
  dealer: {
    label: 'Dealer',
    component: DealerFormField,
  },
  deliverers: {
    label: 'Bezorgers',
    component: DeliverersFormField,
    required: true,
  },
  test_drive_date: {
    label: 'Datum proefrit',
    component: FormDateTime,
    required: true,
  },
  first_name: {
    label: 'Voornaam',
    required: true,
  },
  last_name: {
    label: 'Achternaam',
    required: true,
  },
  gender: {
    label: 'Geslacht',
    component: FormSelect,
    formProps: {
      list: [
        { id: 'm', children: 'Man' },
        { id: 'f', children: 'Vrouw' },
        { id: 'u', children: 'Onbekend' },
      ],
      size: 3,
    },
    required: true,
  },
  street: {
    label: 'Straatnaam + huisnummer',
    required: true,
  },
  city: {
    label: 'Woonplaats',
    required: true,
  },
  zipcode: {
    label: 'Postcode',
    required: true,
  },
  email: {
    label: 'Emailadres',
    required: true,
  },
  phone_number: {
    label: 'Telefoonnummer',
  },
  company: {
    label: 'Bedrijf',
  },
  notes: {
    label: 'Notities',
    component: FormTextArea,
    formProps: { rows: 5 },
  }
  // test_drive_date
  // notes
};


export default dossierFormFields;