import { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';

import { use } from '../../redux/factory';
import {
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  FormCheckbox,
  SmallSpinner,
  CreateButton,
  DataTable,
  SyncButton,
  DeleteConfirmButton,
} from '../shared/ReactToolbox';



const formFields = {
  name: {
    label: 'Naam',
    required: true,
  },
  username: {
    label: 'Gebruikersnaam',
    required: true,
  },
  email: {
    label: 'Emailadres',
    required: true,
  },
  is_staff: {
    label: 'Is admin',
    component: FormCheckbox,
  },
};

const Actions = ({ user }) => {
  const [userIsLoadingId, setUserIsLoadingId] = useState();
  const { deleteUser } = use.users();
  const { exportUser } = use.notarieelBetalenAccounts();
  
  return <>
    <DeleteConfirmButton
      className='float-end'
      onDelete={() => {
        setUserIsLoadingId(user.id);
        deleteUser(user);
      }}
      loading={exportUser.deleteUser && user.id === userIsLoadingId}
    />
    {!user.is_staff &&
      <SyncButton
        className='float-end'
        variant={user.notarieelbetalen_user_id ? 'light' : 'warning'}
        title='Exporteren naar Notarieel Betalen'
        onClick={() => {
          setUserIsLoadingId(user.id);
          exportUser(user);
        }}
        loading={exportUser.isLoading && user.id === userIsLoadingId}
      >
        {!user.notarieelbetalen_user_id && 'Exporteren'}
      </SyncButton>
    }
  </>;
}

const columns = [
  {
    orderBy: 'name',
    name: 'Naam',
    selector: user => user.name,
  },
  {
    orderBy: 'username',
    name: 'Gebruikersnaam',
    selector: user => user.username,
  },
  {
    orderBy: 'email',
    name: 'Emailadres',
    selector: user => user.email,
  },
  {
    name: 'Acties',
    selector: user => <Actions user={user} />,
  },
];

const UsersList = () => {
  const {
    usersList,
    getUsersList,
    createUser,
    updateUser,
    deleteUser,
  } = use.users();
  useEffect(() => {
    getUsersList();
  }, []);

  const [userInCreateModal, setUserInCreateModal] = useState(false);
  const [userInEditModal, setUserInEditModal] = useState(null);

  if (!usersList) return <SmallSpinner className='vertical-space' />;

  return <>
    <Container className="container-list">
      <h4>Admins</h4>
      <DataTable
        showHeader={false}
        columns={columns}
        data={Object.values(usersList).filter(({ is_staff }) => is_staff)}
        orderByDefault='id'
        onClickRow={setUserInEditModal}
      />

      <CreateButton onClick={() => setUserInCreateModal({ locations: [], roles: [], is_staff: true })} />
      <br />
      <br />
      <h4>Bezorgers</h4>
      <DataTable
        filterColumn={({ name, username }) => `${name} ${username}`}
        columns={columns}
        data={Object.values(usersList).filter(({ is_staff }) => !is_staff)}
        orderByDefault='id'
        onClickRow={setUserInEditModal}
      />

      <CreateButton onClick={() => setUserInCreateModal({ locations: [], roles: [], is_staff: false })} />

      {userInCreateModal &&
        <CreateModal
          onHide={() => setUserInCreateModal(null)}
          modalTitle={`${userInCreateModal.is_staff ? 'Admin' : 'Bezorger'} toevoegen`}
          loading={createUser.isLoading}
          initialState={userInCreateModal}
          formFields={formFields}
          onSave={user => createUser(
            user,
            { callback: () => setUserInCreateModal(null) }
          )}
        />
      }

      {userInEditModal &&
        <EditModal
          onHide={() => setUserInEditModal(null)}
          modalTitle={`${userInEditModal.is_staff ? 'Admin' : 'Gebruiker'} aanpassen`}
          loading={updateUser.isLoading}
          initialState={userInEditModal}
          formFields={{
            ...formFields,
            ...!userInEditModal.is_staff
              ? { notarieelbetalen_user_id: { label: 'Notarieel Betalen gebruikers id' } }
              : {}
          }}
          onSave={user => updateUser(
            user,
            { callback: () => setUserInEditModal(null) }
          )}
        />
      }
    </Container>
  </>;
};

export default UsersList
