import { FormSelect } from '../shared/ReactToolbox';
import { use } from '../../redux/factory';

const NotarieelBetalenAccountFormField = ({
  value,
  initialValue,
  setState,
  ...restProps
}) => {
  const { notarieelBetalenAccountsList } = use.notarieelBetalenAccounts();
  const { dealersList } = use.dealers();
  const idsDisabled = (
    Object.values(dealersList)
    .map(({ notarieelbetalen_account_id }) => notarieelbetalen_account_id)
    .filter(id => initialValue !== id)
  )
  return (
    <FormSelect
      {...restProps}
      value={value}
      list={
        Object.values(notarieelBetalenAccountsList).sort((d1, d2) => d1.name > d2.name ? 1 : -1)
      }
      integer
      disabled={({ value }) => idsDisabled.includes(value)}
      onChange={
        notarieelbetalen_account_id =>
          setState({
            notarieelbetalen_account_id,
            name: notarieelbetalen_account_id ? notarieelBetalenAccountsList[notarieelbetalen_account_id].name : '',
          })
      }
      formatTitle={({ name }) => name}
    />
  );
}

export default NotarieelBetalenAccountFormField;